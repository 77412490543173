import s from "./Supplies.module.css"
import photo1 from '../../assets/payback/left/1.jpg'
import photo2 from '../../assets/payback/left/2.jpg'
import photo3 from '../../assets/payback/left/3.jpg'
import photo4 from '../../assets/payback/left/01.jpeg'
import Item from "./Item/Item";

export const Supplies = () => {

    const suppliesData = [
        {
            city: "Белогорск", equipment: 'АБЗ LB1000',
            description: [
                'Производительность - 80 тонн/час',
                'Топливо горелки - мазут',
                'Вместимость асфальтосмесителя - 1000 кг',
                'Точность взвешивания горячих минер. - ±0,5%',
                'Точность взвешивания битума - ±0,3%',
                'Точность взвешивания мин. порошка - ±0,2%',
                'Температура готового асфальта - 130~165° (регул.)',
                'Исходящие пыли - 20mg/Nm',
                'Шум - 70db(A)',
                'Управление - автоматическое',
            ],
            img: photo1,
            features: 'Предоставлена расширенная гарантия'
        },
        {
            city: "Магадан", equipment: 'Мобильный АБЗ QLB-20',
            description: [
                'Производительность - 20 тонн/час',
                'Топливо горелки - дизель',
                'Расход дизеля - 6.5-7.5 кг/тн',
                'Емкость смесителя - 0,75 м3/партия',
                'Бункеры инертных материалов - 3 х 8м3',
                'Температура готового асфальта - 120~165° (регул.)',
            ],
            img: photo2,
            features: 'Доставили в сжатые сроки, несмотря на закрытие таможни и эпидемию'
        },
        {
            city: "Якутск", equipment: 'АБЗ LB1000',
            description: [
                'Производительность - 80 тонн/час',
                'Топливо горелки - дизель',
                'Расход дизеля - 5.5-7.5 кг/тн',
                'Вместимость асфальтосмесителя - 1000 кг',
                'Точность взвешивания горячих минер. - ±0,5%',
                'Точность взвешивания битума - ±0,3%',
                'Точность взвешивания мин. порошка - ±0,2%',
                'Температура готового асфальта - 130~165° (регул.)',
                'Исходящие пыли - 20mg/Nm',
                'Шум - 70db(A)',
                'Управление - автоматическое',
            ],
            img: photo3,
            features: 'Помогли клиенту выгодно приобрести оборудование в лизинг'
        },
        {
            city: "Ытык-Кюёль", equipment: 'АБЗ LB1000',
            description: [
                'Производительность - 80 тонн/час',
                'Топливо горелки - дизель',
                'Расход дизеля - 5.5-7.5 кг/тн',
                'Вместимость асфальтосмесителя - 1000 кг',
                'Точность взвешивания горячих минер. - ±0,5%',
                'Точность взвешивания битума - ±0,3%',
                'Точность взвешивания мин. порошка - ±0,2%',
                'Температура готового асфальта - 130~165° (регул.)',
                'Исходящие пыли - 20mg/Nm',
                'Шум - 70db(A)',
                'Управление - автоматическое',
            ],
            img: photo4,
            features: 'Проведено обучение работников заказчика'
        },
    ]

    return (
        <div className={s.supplies} id="supplies">

            <div className={s.secondTitle}>
                <h2>Примеры поставок оборудования</h2>
            </div>

            <div className={s.suppliesContent}>
                        {suppliesData.map((s, i) => <Item key={i}
                                                                       city={s.city}
                                                                       equipment={s.equipment}
                                                                       description={s.description}
                                                                       img={s.img}
                                                                       features={s.features}/>
                        )}
            </div>

        </div>
    )
}