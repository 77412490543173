import s from "./Payback.module.css"
import leftPhoto1 from '../../assets/payback/left/01.jpeg'
import leftPhoto2 from '../../assets/payback/left/2.jpg'
import leftPhoto3 from '../../assets/payback/left/5.jpg'
import leftPhoto4 from '../../assets/payback/left/6.jpg'
import leftPhoto5 from '../../assets/payback/left/7.jpg'
import rightPhoto1 from '../../assets/payback/photo1.jpg'
import rightPhoto2 from '../../assets/payback/photo2.jpg'
import rightPhoto3 from '../../assets/payback/photo3.jpeg'
import rightPhoto4 from '../../assets/payback/photo4.jpeg'
import rightPhoto5 from '../../assets/payback/photo5.jpeg'
import ModalFunc from "../../common/Modal/Modal";
import Carousel from "../../common/Carousel/Carousel";

export const Payback = () => {

    const imagesLeft = [
        {
            "text": "Description of image",
            "link": leftPhoto1,
        },
        {
            "text": "Rubber paver machine TPJ",
            "link": leftPhoto2,
        },
        {
            "text": "Rubber mixer",
            "link": leftPhoto3,
        },
        {
            "text": "Description of image",
            "link": leftPhoto4,
        },
        {
            "text": "Description of image",
            "link": leftPhoto5,
        },
    ];
    const imagesRight = [
        {
            "text": "Description of image",
            "link": rightPhoto1,
        },
        {
            "text": "Description of image",
            "link": rightPhoto2,
        },

        {
            "text": "Description of image",
            "link": rightPhoto5,
        },
        {
            "text": "Description of image",
            "link": rightPhoto3,
        },
        {
            "text": "Description of image",
            "link": rightPhoto4,
        },
    ];

    return (
        <div className={s.payback} id="payback">

            <div className={s.secondTitle}>
                <h2>Поможем наладить <span>ваше новое производство</span> асфальтобетонной смеси</h2>
            </div>

            <div className={s.paybackContent}>

                <div className={s.paybackLeft}>
                    <div className={s.container}>
                        <p style={{width: '100%', textAlign: 'center'}}>Примеры оборудования из нашего каталога:</p>
                        <Carousel images={imagesLeft}/>
                        <ul>
                            <li data-emoji="🔰">Стационарные асфальтные заводы</li>
                            <li data-emoji="🔰">Мобильные асфальтные заводы</li>
                            <li data-emoji="🔰">Битумно-эмульсионные установки</li>
                            <li data-emoji="🔰">Смесители LB</li>
                            <li data-emoji="🔰">...а также запчасти и комплектующие</li>
                        </ul>
                        <p style={{marginTop: 0}}>На все оборудование действует гарантия <b>12 месяцев</b>,
                            возможна расширенная гарантия и пост-гарантийное обслуживание.</p>
                    </div>
                </div>

                <div className={s.paybackRight}>
                    <div className={s.container}>
                        <p>Доставим и настроим "под ключ", подписание акта
                            ввода только после пробного включения</p>
                        <ModalFunc images={imagesRight} isGrid={true}/>
                        <p style={{fontSize: "16px", fontStyle: "italic", margin: 0, marginTop: "5px", display: 'inline-block'}}>
                            нажмите, чтобы увеличить
                        </p>🔎
                        <ul>
                            <li data-emoji="🔰">Собственные выездные инженеры</li>
                            <li data-emoji="🔰">Собственная система обучения вашего персонала</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}