import s from "./Selection.module.css"
import React from "react";
import {Form} from "../../common/Form/Form";

export const Selection = () => {

    return (
        <div className={s.selection} id="selection">
            <div className={s.selectionContent}>

                <div className={s.selectionLeft}>

                    <div className={s.container}>
                        <div className={s.secondTitle}>
                            <h3 style={{textAlign: "center"}}>Закажите подбор оборудования для ваших целей</h3>
                            <p style={{textAlign: "center"}}>И получите скидку при подписании договора в течение<br/>2 недель до <b>200 000 руб</b>, а также:</p>
                        </div>
                        <ul>
                            <li data-emoji="•">2-3 варианта асфальтного оборудования</li>
                            <li data-emoji="•">точные сроки поставки <b>"под ключ"</b></li>
                            <li data-emoji="•">запрос лизинга в 12 компаниях</li>
                            <li data-emoji="•">расширенная гарантия и пост-гарантийное обслуживание</li>
                        </ul>
                        <a href="#callbackwidget"><button>Получить подбор оборудования</button></a>
                    </div>

                </div>

                {/*<div className={s.selectionRight}>
                    <Form/>
                </div>*/}

            </div>
        </div>
    )
}