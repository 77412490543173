import s from "./Scheme.module.css"

export const Scheme = () => {

    return (
        <div className={s.scheme} id="scheme">

            <div className={s.secondTitle}>
                <h2><span>Современные</span> компоненты оборудования</h2>
            </div>

            <div className={s.schemeContent}>

                <div className={s.schemeLeft}>
                    <div>
                        <p>Грохот и дозаторы инертных материалов</p>
                        <ul>
                            <li data-emoji="•">Расширенная площадь просеивания, погрешность дозации 1%</li>
                            <li data-emoji="•">Тэнзодатчики TOLEDO (Швейцария)</li>
                            <li data-emoji="•">Вибратор Oli, WAM</li>
                            <li data-emoji="•">Износостойкая облицовка принимающей материал пластины</li>
                            <li data-emoji="•">Облегченная сталь для лучшего колебания</li>
                        </ul>
                    </div>
                    <div>
                        <p>Смеситель LB</p>
                        <ul>
                            <li data-emoji="•">Дополнительные перемешивающие бронелисты</li>
                            <li data-emoji="•">Асинхронное перемешивание для однородного асфальтобетона</li>
                            <li data-emoji="•">Срок службы до 10 лет</li>
                            <li data-emoji="•">Долговечные износостойкие лопасти</li>
                            <li data-emoji="•">Отсутствие зоны "непромеса" в нижней половине</li>
                        </ul>
                    </div>
                </div>

                <div className={s.middle}/>

                <div className={s.schemeRight}>
                    <div>
                        <p>Шнековый транспортер</p>
                        <ul>
                            <li data-emoji="•">Производительность до 125 тонн/чаc</li>
                            <li data-emoji="•">Двойная балансировка вала при изготовлении</li>
                            <li data-emoji="•">Мотор-редуктор КПД 99%</li>
                            <li data-emoji="•">Полностью разборный</li>
                            <li data-emoji="•">Диаметр ⌀ 114-323 мм</li>
                        </ul>
                    </div>
                    <div>
                        <p>Другие особенности заводов LB и QLB</p>
                        <ul>
                            <li data-emoji="•">Очиститель конвейерной ленты от налипшего материала</li>
                            <li data-emoji="•">Сушильный барабан с теплоизоляционным покрытием</li>
                            <li data-emoji="•">Двухпоточная пылеочистка с предварительным<br/>удалением крупных частиц</li>
                            <li data-emoji="•">Проточный нагреватель сохраняет температуру<br/>топлива горелки в холодную погоду</li>
                            <li data-emoji="•">Авт. система управления на русском языке</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}