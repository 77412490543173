import s from "./Commercial.module.css"
import React from "react";
import {Form} from "../../common/Form/Form";

export const Commercial = () => {

    return (
        <div className={s.commercial} id="commercial">
            <div className={s.commercialContent}>

                <div className={s.commercialLeft}>

                    <div className={s.container}>
                        <div className={s.secondTitle}>
                            <h3 style={{textAlign: "center"}}>Закажите коммерческое предложение прямо сейчас</h3>
                            <p style={{textAlign: "center"}}>И получите скидку при подписании договора в течение<br/>2 недель до <b>200 000 руб</b>, а также:</p>
                        </div>
                        <ul>
                            <li data-emoji="•">2-3 варианта асфальтного оборудования</li>
                            <li data-emoji="•">точные сроки поставки <b>"под ключ"</b></li>
                            <li data-emoji="•">запрос лизинга в 12 компаниях</li>
                            <li data-emoji="•">расширенная гарантия и пост-гарантийное обслуживание</li>
                        </ul>
                        <a href="#callbackwidget"><button>Получить коммерческое предложение</button></a>
                    </div>

                </div>

                {/*<div className={s.commercialRight}>
                    <Form/>
                </div>*/}

            </div>
        </div>
    )
}